.inredning-grid {
  margin-top: 100px;

  @media (max-width: $max-mobile) {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .col-grid {
    margin-bottom: 150px;
  }

  .col-grid-background {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -150px;
    p {
      color: white;
      margin: 0;
      background: #ffc61cd4;
      padding: 20px 25px;
      min-width: 150px;
      font-size: 14px;
      width: 100%;
    }
  }

  img {
    height: 250px;
    width: 100%;
  }
}

.inredningsprodukter {
  text-align: center;
  margin-top: -30px;
  color: $primary;
  display: flex;
  text-decoration: none;
  justify-content: center;
  transition: 0.5s ease-in-out;
  flex-direction: column;
  &:hover {
    color: $primary;
    opacity: 0.7;
  }
}

#inredningsprodukterna {
  padding-top: 30px;
}
