.Header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffc61c;
  display: flex;
  align-items: center;
  background: $mainBackground;

  .nav {
    justify-content: flex-end;

    .custom-dropdown {
      margin-left: 10px;
    }

    .link-a {
      padding: 5px 30px;
    }
    .nav-link {
      color: $primary;
      .show {
        background-color: $primary;
      }
    }

    .dropdown-item.active {
      background: $primary;
    }

    .dropdown-item {
      &:active {
        background-color: $primary;
        color: white;
      }
      a {
        height: 100%;
        color: black;
        text-decoration: none;
      }
    }

    .btn-outline-primary:focus {
      box-shadow: none;
    }

    button {
      border: none;
      text-decoration: none;
      color: white;
      font-size: $SmallText;
    }
  }
}

.logo {
  @media (max-width: 900px) {
    max-width: 300px;
  }
}
