.Home {
  height: 90vh;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    margin: auto;
    height: 100%;
    margin: 20px 0;
  }

  h1 {
    color: $white;
    font-size: $VeryBigText;
    max-width: 400px;
    @media (max-width: 900px) {
      font-size: 20px;
    }
  }
}

// pagination

.under__title {
  display: flex;
  align-items: center;
  .borderWidth {
    width: 100px;
    background: $primary;
    height: 1px;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }

  p {
    color: $primary;
    margin: 0;
    font-size: $SmallText;
  }
}
