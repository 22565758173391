.textImage {
  background-size: cover;
  padding: 50px 20px;
  display: flex;
  justify-content: center;

  .overlay {
    p {
      font-size: $SmallText;
      color: $white;
      max-width: 650px;
      text-align: justify;
    }
  }
}
