.kontakta {
  padding-bottom: 100px !important;
  height: 100%;
  width: 100%;

  h2 {
    color: white;
  }
  p {
    margin: 0;
    &:nth-child(3) {
      margin-bottom: 20px;
    }
  }

  .header-title {
    margin-left: 30px;
  }

  .col-form {
    margin: 0;
    padding: 20px 20px;
    max-width: 500px;
  }
}
