.footer {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000075;


  div {
    @media (max-width: $max-mobile) {
      margin-top: 20px;
    }
  }

  @media (max-width: $max-mobile) {
    height: 100%;
    padding: 30px 10px;
  }

  a {
    text-decoration: none;
  }

  p {
    color: $primary;
  }

  .footer-nav {
    display: flex;
    flex-direction: column;
    color: $primary;
    button {
      text-transform: capitalize;
      color: $primary;
      font-size: 16px;

      @media (max-width: $max-mobile) {
        padding-left: 0;
        justify-content: flex-start;
      }
    }
  }
}
