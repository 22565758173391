.jobb {
  align-items: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 900px) {
    height: 100%;
    margin: 50px 0;
  }
  
  .nojobs {
    color: $primary;
    padding: 50px;
    width: 100%;
    height: 200px;
  }

  h4 {
    color: $primary;
  }
  p {
    color: $primary;
  }
}

.jobb-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  padding-top: 0;
  a {
    text-decoration: none;
  }
  button {
    background: $primary;
    &:hover {
      background: white;
      color: $primary;
    }
  }
}
