.SingleProdukt {
  margin-top: 50px;

  .singleprodukt-row {
    display: flex;
    min-width: 800px;
    align-items: center;
    margin: 50px 0px;
    justify-content: center;
    @media (max-width: $max-mobile) {
      min-width: auto;
    }

    p {
      color: white;
      font-size: 18px;
    }

    img {
      object-fit: cover;

      @media (max-width: $max-mobile) {
        max-width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

.loading {
  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
}
