$mainBackground: #121212;
$white: #ffff;
$primary: #ffc61c;

$SmallText: 15px;
$VeryBigText: 64px;



$max-mobile: 900px;
