.image-grid {
  .overlay {
    position: absolute;
    color: $white;
    margin-top: -80px;
    display: flex;
    align-items: center;
    p {
      background: #ffc61cd4;
      padding: 10px;
      min-width: 120px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      font-weight: bold;
    }
  }
  img {
    min-height: 400px;
    max-height: 400px;
    max-width: 220px;

    @media (max-width: $max-mobile) {
      max-width: 100%;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}
