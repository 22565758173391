.form {
  button {
    margin-top: 10px;
  }

  div {
    width: 100%;
    margin-top: 10px;
    color: white;
  }
}

.primary-background {
  background: $primary;
  border-radius: 2px;
  padding: 30px;
  .col:nth-child(0) {
    padding-left: 50px;
  }
  .col {
    padding: 0;
  }
}
