$theme-colors: (
  'primary': #ffc61c,
  'danger': #ff4136,
);

h1 {
  @media (max-width: 900px) {
    font-size: 20px;
  }
}

@import '~bootstrap/scss/bootstrap';
@import 'Assets/Sass/veribles';
@import 'Assets/Sass/Header';
@import 'Assets/Sass/Home';
@import 'Assets/Sass/About';
@import 'Assets/Sass/Pagination';
@import 'Assets/Sass/Produkter';
@import 'Assets/Sass/Components/ImageOverlay';
@import 'Assets/Sass/Components/Lottie';
@import 'Assets/Sass/Components/Jobb';
@import 'Assets/Sass/Components/Email';
@import 'Assets/Sass/Components/Carousel';
@import 'Assets/Sass/Components/Motion';
@import 'Assets/Sass/Components/InredningsGrid';
@import 'Assets/Sass/Components/Footer';
@import 'Assets/Sass/Components/SingleProdukt';
@import 'Assets/Sass/Components/Kontakta';

body {
  background: $mainBackground;
}

.justify-content-center {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
