.produkter {
  height: 90vh;
  @media (max-width: $max-mobile) {
    height: 100%;
    margin-top: 20px;
  }
  .image-grid {
    margin-top: 40px;
  }

  .row {
    @media (max-width: $max-mobile) {
      height: 100%;
      width: 100%;
      flex-direction: column;
    }
  }
}
